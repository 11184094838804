import React, { useState, useEffect, useMemo } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SearchBar from '../components/searchbar/searchbar';
import useApiGet from '../hooks/useApiGet';
import {PageLoader} from '../components/page-loader';
import GranteeList from '../components/granteeList/granteeList';

const GranteesPage = () => {
  const { data, error, loading } = useApiGet('grantees');
  const [grantees, setGrantees] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (!loading && !error && data) {
      setGrantees(data.grantees);
    }
  }, [data, loading, error]);

  const filteredGrantees = useMemo(() => {
    return grantees.filter(grantee =>
      grantee.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [grantees, searchTerm]);

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
  };

  if (loading | !grantees) return <PageLoader />;
  if (error) return <div>Error loading grantees.</div>;

  return (
    <Container>
      <Row>
        <Col>
          <SearchBar onSearch={handleSearch} />
        </Col>

      </Row>
      <GranteeList grantees={filteredGrantees}/>
    </Container>
  );
};

export default GranteesPage;

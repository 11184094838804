// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #eee;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
}

.label {
    color: #797979;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.text-bold {
    color: #292D32;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.description {
    color: var(--text-body, #292D32);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.custom-link {
    color: #007bff;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/components/granteeSummary/granteeSummary.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,aAAa;IACb,6BAA6B;IAC7B,WAAW;IACX,kCAAkC;IAClC,eAAe;IACf,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,oBAAoB;IACpB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,oBAAoB;IACpB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gCAAgC;IAChC,oBAAoB;IACpB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,oBAAoB;IACpB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,qBAAqB;AACzB","sourcesContent":["\n.header {\n    display: flex;\n    justify-content: space-between;\n    padding: 10px;\n    border-bottom: 1px solid #eee;\n    color: #000;\n    font-family: 'Poppins', sans-serif;\n    font-size: 22px;\n    font-weight: 600;\n    font-style: normal;\n}\n\n.label {\n    color: #797979;\n    font-family: Poppins;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: normal;\n}\n\n.text-bold {\n    color: #292D32;\n    font-family: Poppins;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal;\n}\n\n.description {\n    color: var(--text-body, #292D32);\n    font-family: Poppins;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n}\n\n.custom-link {\n    color: #007bff;\n    font-family: Poppins;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: normal;\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

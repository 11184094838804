import React from 'react';
import NewGranteeForm from '../components/newGranteeForm/newGranteeForm';

const NewGranteePage = () => {
  return (
    <div className="container">
      <h1>Create New Grantee</h1>
      <NewGranteeForm />
    </div>
  );
};

export default NewGranteePage;

import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Nav } from "react-bootstrap";

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/home",
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  };

  return (
    <Nav className="loginButton">
      <Nav.Link onClick={handleLogin}>Log In</Nav.Link>
    </Nav>

  );
};

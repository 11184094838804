import React, { useState, useEffect } from 'react';
import ImageViewer from '../imageViewer/imageViewer';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import ApiPatch from '../../api/apiPatch';
import './listDetailUpdates.css';

const ListDetailUpdates = ({ updates }) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [localUpdates, setLocalUpdates] = useState(updates);
    const { getAccessTokenSilently } = useAuth0();
    const [viewMode, setViewMode] = useState('list'); // 'list' or 'details'

    
    useEffect(() => {
        setLocalUpdates(updates);
    }, [updates]);

    const markAsRead = async (data) => {
        if (data.read) {
            return true;
        }
        const token = await getAccessTokenSilently();
        const url = `updates/trustee-update`;
        const body = {
            trustee_id: data.trustee_id,
            update: data.update,
            read: true,
        };
        const result = await ApiPatch(url, body, token);

        return result;
    };

    const handleSelectItem = async (data) => {
        setSelectedItem(data);
        setViewMode('details');
        const updateSuccess = await markAsRead(data);
        if (updateSuccess) {
            const updatedList = localUpdates.map(item =>
                item.update.id === data.update.id ? { ...item, read: true } : item
            );
            setLocalUpdates(updatedList); // Update the local state
            setSelectedItem({ ...data, read: true });
        } else {
            setSelectedItem(data);
        }
    };

    const handleBackToList = () => {
        setViewMode('list');
        setSelectedItem(null);
        
    };

    
    if (!localUpdates || localUpdates.length === 0) {
        return (
            <Container>
                <Row>
                    <Col>
                        No updates found.
                    </Col>
                </Row>
            </Container>
        );
    }
    
    return (
        <Container fluid>
            <Row>
                {/* List Section - Always show on lg screens, toggle on smaller screens */}
                <Col lg={3} xs={12} className={viewMode === 'details' && 'd-lg-block d-none'}>
                    <div className="list-group custom-list-group">
                        {localUpdates.map((item, index) => (
                            <button 
                                type="button" 
                                className={`
                                list-group-item 
                                list-group-item-action 
                                ${item.read ? "read" : "unread"} 
                                ${selectedItem && selectedItem.update.id === item.update.id ? 'selected' : ''}`}
                                key={index}
                                onClick={() => handleSelectItem(item)}
                            >
                                Update from {item.update.grantee.name}
                            </button>
                        ))}
                    </div>
                </Col>

                {/* Details Section - Conditional rendering based on viewMode */}
                <Col lg={9} xs={12} className={viewMode === 'list' && 'd-lg-block d-none'}>
                    {selectedItem ? (
                        <div>
                            <Button variant="secondary" className="mb-3 d-lg-none" onClick={handleBackToList}>
                                Back to List
                            </Button>
                            <p>{selectedItem.update.notes}</p>
                            <ImageViewer url={selectedItem.update.image_urls[0]} />
                        </div>
                    ) : (
                        <div className="alert alert-info">Select an update to view details</div>
                    )}
                </Col>
            </Row>
        </Container>
    );
};


export default ListDetailUpdates;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

import HomePage from './views/HomePage/HomePage';
import GranteesPage from './views/GranteesPage';
import GranteePage from './views/GranteePage';
import { AuthenticationGuard } from './components/authentication-gaurd';
import Dashboard from './components/dashboard/dashboard';
import ScrollToTop from './utils/scrollToTop';
import UpdatesPage from './views/UpdatesPage';
import NewGrantPage from './views/NewGrantPage';
import NewGranteePage from './views/NewGranteePage';
import ProposalsPage from './views/ProposalsPage';
import ProposalPage from './views/ProposalPage';

const App = () => {

  return (
    <Dashboard>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/callback" element={<HomePage />} />
        <Route path="/grantees" element={<AuthenticationGuard component={GranteesPage} />} />
        <Route path="/grantees/:grantee_id" element={<AuthenticationGuard component={GranteePage} />} />
        <Route path="/updates" element={<AuthenticationGuard component={UpdatesPage} />} />
        <Route path="/new-grant" element={<AuthenticationGuard component={NewGrantPage} />} />
        <Route path="/new-grantee" element={<AuthenticationGuard component={NewGranteePage} />} />
        <Route path="/proposals" element={<AuthenticationGuard component={ProposalsPage} />} />
        <Route path="/proposals/:proposal_id" element={<AuthenticationGuard component={ProposalPage} />} />
        {/* Add other routes here as needed */}
      </Routes>
    </Dashboard>

  );
};

export default App;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import useApiGet from '../hooks/useApiGet';
import { PageLoader } from '../components/page-loader';
import GranteeSummary from '../components/granteeSummary/granteeSummary';
import GranteeTabs from '../components/granteeTabs/granteeTabs';


const GranteePage = () => {
  const { grantee_id } = useParams();
  const { data: granteeData, error: granteeError, loading: granteeLoading } = useApiGet(`grantees/${grantee_id}/details`);
  const { data: granteeImageData, error: granteeImageError, loading: granteeImageLoading } = useApiGet(`grantees/${grantee_id}/images`);
  
  const [grantee, setGrantee] = useState({ giving_history: [] });
  const [images, setImages] = useState({ thank_you_notes: [], progress_reports: [], grant_requests: [] });
  
  
  const isLoading = granteeLoading || granteeImageLoading;
  const error = granteeError || granteeImageError;
  

  useEffect(() => {
    if (granteeData) {
      setGrantee(granteeData);
    }
    if (granteeImageData) {
      setImages(granteeImageData);
    }
  }, [granteeData, granteeImageData]);
  if (isLoading) return <PageLoader />;

  if (error) return <div>Error loading data.</div>;
  return (
    <Container>
      {grantee && <GranteeSummary grantee={grantee} />}
      {grantee && images && <GranteeTabs grantee={grantee} images={images} />}
    </Container>
  );
};

export default GranteePage;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-list-group .list-group-item.unread {
    font-weight: bold;
    background-color: #ffffff; 
}

.custom-list-group .list-group-item.read {
    background-color: #BBB; 
}

.custom-list-group .list-group-item.selected {
    background-color: #d0e0f0; /* Bootstrap's default blue */
    color: black;
}
`, "",{"version":3,"sources":["webpack://./src/components/listDetailUpdates/listDetailUpdates.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB,EAAE,6BAA6B;IACxD,YAAY;AAChB","sourcesContent":[".custom-list-group .list-group-item.unread {\n    font-weight: bold;\n    background-color: #ffffff; \n}\n\n.custom-list-group .list-group-item.read {\n    background-color: #BBB; \n}\n\n.custom-list-group .list-group-item.selected {\n    background-color: #d0e0f0; /* Bootstrap's default blue */\n    color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

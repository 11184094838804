// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grantee-tabs {
    gap: 2px;
    display: flex;             /* Ensures that tabs are laid out inline */
    justify-content: space-evenly; /* Distributes space evenly around all tabs, remove if not required */
    overflow: hidden;          /* Prevents tabs from overflowing the tab list */
}

.grantee-tab {
    padding: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    flex: 1 1;             /* Flex grow, shrink and basis set to occupy equal width */
    display: flex;             /* Use flex to align text in the center */
    justify-content: center;   /* Center text horizontally */
    text-align: center;        /* Center text lines if they wrap */
    white-space: nowrap;       /* Prevents text from wrapping to the next line */
    overflow: hidden;          /* Hide text that overflows the tab width */
    text-overflow: ellipsis;   /* Use ellipsis for overflow text */
}

.grantee-tab.active {
    background-color: #e9ecef;
    color: #292D32;
    border: #555 solid 1px;
}

.grantee-tab:not(.active) {
    background-color: #F2F7FB;
    color: #6c757d;
}
`, "",{"version":3,"sources":["webpack://./src/components/granteeTabs/granteeTabs.css"],"names":[],"mappings":"AAAA;IACI,QAAQ;IACR,aAAa,cAAc,0CAA0C;IACrE,6BAA6B,EAAE,qEAAqE;IACpG,gBAAgB,WAAW,gDAAgD;AAC/E;;AAEA;IACI,aAAa;IACb,kCAAkC;IAClC,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,wCAAwC;IACxC,SAAa,cAAc,0DAA0D;IACrF,aAAa,cAAc,yCAAyC;IACpE,uBAAuB,IAAI,6BAA6B;IACxD,kBAAkB,SAAS,mCAAmC;IAC9D,mBAAmB,QAAQ,iDAAiD;IAC5E,gBAAgB,WAAW,2CAA2C;IACtE,uBAAuB,IAAI,mCAAmC;AAClE;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB","sourcesContent":[".grantee-tabs {\n    gap: 2px;\n    display: flex;             /* Ensures that tabs are laid out inline */\n    justify-content: space-evenly; /* Distributes space evenly around all tabs, remove if not required */\n    overflow: hidden;          /* Prevents tabs from overflowing the tab list */\n}\n\n.grantee-tab {\n    padding: 10px;\n    font-family: 'Poppins', sans-serif;\n    font-size: 14px;\n    font-weight: 600;\n    cursor: pointer;\n    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);\n    flex: 1 1 0px;             /* Flex grow, shrink and basis set to occupy equal width */\n    display: flex;             /* Use flex to align text in the center */\n    justify-content: center;   /* Center text horizontally */\n    text-align: center;        /* Center text lines if they wrap */\n    white-space: nowrap;       /* Prevents text from wrapping to the next line */\n    overflow: hidden;          /* Hide text that overflows the tab width */\n    text-overflow: ellipsis;   /* Use ellipsis for overflow text */\n}\n\n.grantee-tab.active {\n    background-color: #e9ecef;\n    color: #292D32;\n    border: #555 solid 1px;\n}\n\n.grantee-tab:not(.active) {\n    background-color: #F2F7FB;\n    color: #6c757d;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

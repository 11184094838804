// src/components/NewGrantForm.js
import React, { useState, useEffect } from 'react';
import { Form, Button, ListGroup, InputGroup, FormControl, Alert } from 'react-bootstrap';
import ApiPost from '../../api/apiPost'; 
import { useAuth0 } from '@auth0/auth0-react';
import useApiGet from '../../hooks/useApiGet';

const NewGrantForm = () => {
  const [selectedGrantee, setSelectedGrantee] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredGrantees, setFilteredGrantees] = useState([]);
  const [alertMessage, setAlertMessage] = useState(null);
  const [amount, setAmount] = useState('');
  const [notes, setNotes] = useState('');
  const [dateApproved, setDateApproved] = useState('');
  const [datePaid, setDatePaid] = useState('');
  const { getAccessTokenSilently } = useAuth0();
  const { data, error, loading } = useApiGet('grantees');

  useEffect(() => {
    if (data && searchTerm && !error && !loading) {
      const filtered = data.grantees.filter(grantee =>
        grantee.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredGrantees(filtered);
      if (filtered.length === 1) {
        setSelectedGrantee(filtered[0]);
        setSearchTerm(filtered[0].name);
      }
    } else {
      setFilteredGrantees([]);
    }
  }, [searchTerm, data, error, loading]);

  const handleSelectGrantee = (grantee) => {
    setSelectedGrantee(grantee);
    setSearchTerm(grantee.name);
    setFilteredGrantees([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedGrantee) {
      alert('Please select a grantee.');
      return;
    }

    const newGrant = {
      grantee_id: selectedGrantee.id,
      amount: parseInt(amount),
      notes,
      date_approved: dateApproved,
      date_paid: datePaid,
    };

    const token = await getAccessTokenSilently();
    const url = `grantees/${selectedGrantee.id}/grant`;
    const success = await ApiPost(url, newGrant, token);

    if (success) {
      console.log('Grant created successfully', newGrant);
      setAlertMessage('Grant created successfully');
      setSelectedGrantee(null);
      setSearchTerm('');
      setAmount('');
      setNotes('');
      setDateApproved('');
      setDatePaid('');
    } else {
      console.error('Error creating grant');
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
       {alertMessage && <Alert variant="success">{alertMessage}</Alert>}
      <Form.Group controlId="formGrantee">
        <Form.Label>Grantee</Form.Label>
        <InputGroup>
          <FormControl
            type="text"
            placeholder="Search for grantee"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            required
          />
        </InputGroup>
        {filteredGrantees.length > 1 && (
          <ListGroup>
            {filteredGrantees.map(grantee => (
              <ListGroup.Item
                key={grantee.id}
                onClick={() => handleSelectGrantee(grantee)}
                action
              >
                {grantee.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Form.Group>

      <Form.Group controlId="formAmount">
        <Form.Label>Amount</Form.Label>
        <Form.Control
          type="number"
          placeholder="Enter amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          required
        />
      </Form.Group>

      <Form.Group controlId="formNotes">
        <Form.Label>Notes</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="formDateApproved">
        <Form.Label>Date Approved</Form.Label>
        <Form.Control
          type="date"
          value={dateApproved}
          onChange={(e) => setDateApproved(e.target.value)}
          required
        />
      </Form.Group>

      <Form.Group controlId="formDatePaid">
        <Form.Label>Date Paid</Form.Label>
        <Form.Control
          type="date"
          value={datePaid}
          onChange={(e) => setDatePaid(e.target.value)}
        />
      </Form.Group>

      <Button variant="primary" type="submit">
        Create Grant
      </Button>
    </Form>
  );
};

export default NewGrantForm;

import React from 'react';
import { Col, Container, Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { LoginButton } from '../buttons/login-button';
import { useAuth0 } from "@auth0/auth0-react";
import { LogoutButton } from '../buttons/logout-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faComment, faHandHoldingDollar, faBell, faPlus } from '@fortawesome/free-solid-svg-icons';


import './sidebar.css';

const SideBar = () => {
    const { isAuthenticated } = useAuth0();
    const location = useLocation(); // Location object represents the current URL

    const getActiveKey = () => {
        const path = location.pathname.split('/')[1];
        return path;
    }

    return (
        <>
        <Col xs={12} xl={2} className='sidebar-container fixed-top d-none d-xl-block'>
            <div className="sidebar d-flex flex-column justify-content-between">
                <div>
                    <div className='brand-name'>Garland Foundation</div>
                    {/* medium screens and up */}
                    <Nav variant="pills" activeKey={getActiveKey()} className="flex-xl-column">
                        <LinkContainer to="/home">
                            <Nav.Link eventKey="home"><FontAwesomeIcon icon={faHome} /> Home</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/grantees">
                            <Nav.Link eventKey="grantees"><FontAwesomeIcon icon={faHandHoldingDollar}/> Grantees</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/updates">
                            <Nav.Link eventKey="updates"><FontAwesomeIcon icon={faBell} />Updates</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/proposals">
                            <Nav.Link eventKey="proposals"><FontAwesomeIcon icon={faComment} />Proposals</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/new-grant">
                            <Nav.Link eventKey="new-grant"><FontAwesomeIcon icon={faPlus} />New Grant</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/new-grantee">
                            <Nav.Link eventKey="new-grantee"><FontAwesomeIcon icon={faPlus} />New Grantee</Nav.Link>
                        </LinkContainer>

                    </Nav>
                </div>
            <div>
                <Nav className='mb-5'>
                    {isAuthenticated ? <LogoutButton /> : <LoginButton />}
                </Nav>
            </div>
            </div>
        </Col>
            {/* small screens */}
            <Navbar collapseOnSelect expand="xl" className="sticky-top d-xl-none">
                <Container>
                    <LinkContainer to="/home">
                        <Navbar.Brand>Garland Foundation</Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className='me-auto' activeKey={getActiveKey()}>
                        <LinkContainer to="/home">
                            <Nav.Link eventKey="home" className="mobile-nav-link"><FontAwesomeIcon icon={faHome} /> Home</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/grantees">
                            <Nav.Link eventKey="grantees" className="mobile-nav-link"><FontAwesomeIcon icon={faHandHoldingDollar}/> Grantees</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/updates">
                            <Nav.Link eventKey="updates" className="mobile-nav-link"><FontAwesomeIcon icon={faBell}/> Updates</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/proposals">
                            <Nav.Link eventKey="proposals" className="mobile-nav-link"><FontAwesomeIcon icon={faComment}/> Proposals</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/new-grant">
                            <Nav.Link eventKey="new-grant" className="mobile-nav-link"><FontAwesomeIcon icon={faPlus}/> New Grant</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/new-grantee">
                            <Nav.Link eventKey="new-grantee" className="mobile-nav-link"><FontAwesomeIcon icon={faPlus}/> New Grantee</Nav.Link>
                        </LinkContainer>
                        {isAuthenticated ? <LogoutButton /> : <LoginButton />}
                    </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default SideBar;

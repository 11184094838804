import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import './imageViewer.css';
import useDeviceType from '../../hooks/useDeviceType';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();


const ImageViewer = ({ url }) => {
  const isPDF = url.includes('.pdf');
  const deviceType = useDeviceType();
  const isDesktop = deviceType === 'Desktop';
  // const isMobile = deviceType === 'Mobile' || deviceType === 'Tablet';
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width:0, height: 0 });

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
    }
  }, []);

  useEffect(() => {
    setPageNumber(1);  // Reset page number to 1 when the URL changes
    setScale(1);       // Reset scale to 1 when the URL changes
  }, [url]);
  
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }
  const goToPrevPage = () => {
    setPageNumber(prevPage => prevPage - 1);
  };
  const goToNextPage = () => {
    setPageNumber(prevPage => prevPage + 1);
  };
  const handleZoomIn = () => {
    setScale(prevScale => prevScale + 0.25);
  }
  const handleZoomOut = () => {
    if (scale <= 0.25) return;
    setScale(prevScale => prevScale - 0.25);
  }
  
  console.log('dimensions', dimensions);
  
  const imageStyle = {
    maxWidth: '100%',  // Ensure the image does not exceed the container's width
    maxHeight: '600px', // Limit the maximum height to maintain a reasonable size
    width: 'auto',     // Automatically adjust the width to preserve aspect ratio
    height: 'auto',    // Automatically adjust the height to preserve aspect ratio
    display: 'block',  // Removes extra space below the image (common with inline elements)
    margin: '0 auto'   // Center the image horizontally if it's smaller than the container
  };
  return (
    <div className="image-container">
      {isPDF ? (
        isDesktop ? (
          <div className="iframe-container">
              <iframe 
                  src={url} 
                  title="Preview"
              ></iframe>
          </div>
        ): (
          <div>
          <div className="zoom-buttons">
            <button onClick={handleZoomIn}>+</button>
            <button onClick={handleZoomOut}>-</button>
          </div>
          {numPages > 1 && (
            <div className="navigation-buttons">
              <button
                disabled={pageNumber <= 1}
                onClick={goToPrevPage}
              >
                Previous
              </button>
              <button
                disabled={pageNumber >= numPages}
                onClick={goToNextPage}
              >
                Next
              </button>
            </div>
          )}
          <div ref={targetRef} className="pdf-container">
            <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} scale={scale} width={dimensions.width} />
            </Document>
          </div>
        </div>
        )
      ) : (
        <img src={url} style={imageStyle} alt="content" />
      )}
    </div>
  );
}

export default ImageViewer;
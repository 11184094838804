
import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";


const ProposalPage = ({proposal}) => {
    return (
        <Container>
          <Row>
            <Col>
              <h1>{proposal.name}</h1>

              {/*Coming soon */}
                <p>Details about the proposal will go here.</p>
                
            </Col>
          </Row>
        </Container>
      );
    };

export default ProposalPage;
import React, { useState, useEffect } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import ApiPost from '../../api/apiPost';
import useApiGet from '../../hooks/useApiGet';
import { useAuth0 } from '@auth0/auth0-react';

const NewGranteeForm = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    category: '',
    webAddress: '',
    trustees: []
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { data: trusteesData, error: trusteesError, loading: trusteesLoading } = useApiGet('trustees');
  const { data: categoriesData, error: categoriesError, loading: categoriesLoading } = useApiGet('categories');

  useEffect(() => {
    if (trusteesError || categoriesError) {
      setError('Error loading data.');
    }
  }, [trusteesError, categoriesError]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevState) => {
      const trusteesSet = new Set(prevState.trustees);
      if (checked) {
        trusteesSet.add(value);
      } else {
        trusteesSet.delete(value);
      }
      return {
        ...prevState,
        trustees: Array.from(trusteesSet)
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await getAccessTokenSilently();
    const granteeData = {
      name: formData.name,
      description: formData.description,
      category_id: formData.category,
      web_address: formData.webAddress,
      trustees: formData.trustees
    };
    const success = await ApiPost('grantees/', granteeData, token);
    if (!success) {
        setError(error);
        setSuccess(null);
    } else {
        // Clear the form and show a success message
        setError(null);
        setSuccess('Grantee ('+ formData.name + ') created successfully.');
        setFormData({
            name: '',
            description: '',
            category: '',
            webAddress: '',
            trustees: []
        });
        }
  };

  if (trusteesLoading || categoriesLoading) return <p>Loading...</p>;

  return (
    <Form onSubmit={handleSubmit}>
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
      <Form.Group controlId="name">
        <Form.Label>Name</Form.Label>
        <Form.Control type="text" name="name" value={formData.name} onChange={handleInputChange} required />
      </Form.Group>

      <Form.Group controlId="description">
        <Form.Label>Description</Form.Label>
        <Form.Control as="textarea" name="description" value={formData.description} onChange={handleInputChange} required />
      </Form.Group>

      <Form.Group controlId="category">
        <Form.Label>Category</Form.Label>
        <Form.Control as="select" name="category" value={formData.category} onChange={handleInputChange} required>
          <option value="">Select a category</option>
          {categoriesData.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <Form.Group controlId="webAddress">
        <Form.Label>Website URL</Form.Label>
        <Form.Control type="url" name="webAddress" value={formData.webAddress} onChange={handleInputChange} required />
      </Form.Group>

      <Form.Group controlId="trustees">
        <Form.Label>Trustees</Form.Label>
        {trusteesData.map((trustee) => (
          <Form.Check
            key={trustee.id}
            type="checkbox"
            label={trustee.full_name}
            value={trustee.id}
            onChange={handleCheckboxChange}
            checked={formData.trustees.includes(trustee.id.toString())}
          />
        ))}
      </Form.Group>

      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
};

export default NewGranteeForm;

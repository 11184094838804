import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import React from 'react';
import { useState } from 'react';
import GrantList from '../grantList/grantList';
import './granteeTabs.css';
import ListDetailDocuments from '../listDetailDocuments/listDetailDocuments';

const GranteeTabs = ({ grantee, images }) => {
    const [key, setKey] = useState('givingHistory');
    return (
        <Tabs
            id="grantee-tabs"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3 grantee-tabs"
            justify
        >
            <Tab eventKey="givingHistory" title="Giving History" tabClassName='grantee-tab'>
                {grantee.giving_history ? <GrantList grants={grantee.giving_history} /> : <p>No giving history available.</p>}
            </Tab>
            <Tab eventKey="documents" title="Documents" tabClassName='grantee-tab'>
                {images ? <ListDetailDocuments items={images} /> : <p>No documents available.</p>}
            </Tab>
        </Tabs>
    );
}

export default GranteeTabs;

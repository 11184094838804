import React, { useEffect } from 'react';
import useApiGet from '../hooks/useApiGet';
import { useState } from 'react';
import ListDetailUpdates from '../components/listDetailUpdates/listDetailUpdates';
import { PageLoader } from '../components/page-loader';



const UpdatesPage = () => {
    const { data, error, loading } = useApiGet('updates/my-grantees');
    const [updates, setUpdates] = useState([]);
    useEffect(() => {
        if (!loading && !error && data) {
            setUpdates(data);
        }
    }, [data, loading, error]);

    if (loading | !updates) return <PageLoader />;

    return (
        <div>
        <ListDetailUpdates updates={updates}/>
        </div>
    );
};

export default UpdatesPage;

const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        // You can specify additional options as needed.
    }).format(amount);
};

export { formatCurrency };
import { Container, Col, Row } from "react-bootstrap"
import GranteeRow from "../granteeRow/granteeRow"
import './granteeList.css'

const GranteeList = ({grantees}) => {
    return (
        <Container>
            <Row className="grantee-list-header">
                <Col xs={7}>Grantee</Col>
                <Col xs={5}>Category</Col>
            </Row>
          {grantees.length > 0 ? (
          grantees.map(grantee => (
            <Col key={grantee.id} md={12}>
              <GranteeRow grantee={grantee} />
            </Col>
          ))
        ) : (
          <Col>No grantees found.</Col>
        )}
      </Container>
    )
}

export default GranteeList
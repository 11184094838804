import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useAuth0 } from "@auth0/auth0-react";

const HomePage = () => {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  console.log(user);
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={6} className="text-center">
          <h1>The Garland Foundation</h1>
          <h2>Established in 1959</h2>
          
          {!isAuthenticated ? (
            <div>
              <p>Contact Jack to activate your account</p>
              <Button onClick={() => loginWithRedirect()} variant="primary">Login</Button>
            </div>
          ) : (
            <div>
              <p>Welcome back, {user.nickname}!</p>
              <Button onClick={() => logout({ returnTo: window.location.origin })} variant="danger">Logout</Button>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default HomePage;

import React, { useState, useMemo } from 'react';
import { Row, Col, Container, Button, Form } from 'react-bootstrap';
import ImageViewer from '../imageViewer/imageViewer';

const ListDetailDocuments = ({ items }) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [viewMode, setViewMode] = useState('list'); // 'list' or 'details'
    const [filter, setFilter] = useState('All');
    const [sort, setSort] = useState('Name');

    const handleSelectItem = (item) => {
        setSelectedItem(item);
        setViewMode('details');
    };

    const handleBackToList = () => {
        setViewMode('list');
        setSelectedItem(null);
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleSortChange = (e) => {
        setSort(e.target.value);
    };

    // Combine the three arrays into one
    const allItems = useMemo(() => {
        const combinedItems = [
            ...items.thank_you_notes.map(item => ({ ...item, type: 'Thank you notes' })),
            ...items.grant_requests.map(item => ({ ...item, type: 'Grant Requests' })),
            ...items.progress_reports.map(item => ({ ...item, type: 'Progress Reports' })),
        ];
        return combinedItems;
    }, [items]);

    const filterItems = (items) => {
        if (filter === 'All') return items;
        return items.filter((item) => item.type === filter);
    };

    const sortItems = (items) => {
        return [...items].sort((a, b) => {
            if (sort === 'Name') return a.key.localeCompare(b.key);
            if (sort === 'Date') return new Date(b.last_modified) - new Date(a.last_modified);
            return 0;
        });
    };

    const filteredAndSortedItems = sortItems(filterItems(allItems));

    if (!allItems || allItems.length === 0) {
        return (
            <Container>
                <Row>
                    <Col>
                        No files found.
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <Container fluid>
            <Row className="mb-3">
                <Col>
                    <Form.Group>
                        <Form.Label>Filter by</Form.Label>
                        <Form.Control as="select" value={filter} onChange={handleFilterChange}>
                            <option value="All">All</option>
                            <option value="Thank you notes">Thank you notes</option>
                            <option value="Progress Reports">Progress Reports</option>
                            <option value="Grant Requests">Grant Requests</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Sort by</Form.Label>
                        <Form.Control as="select" value={sort} onChange={handleSortChange}>
                            <option value="Name">Name (A-Z)</option>
                            <option value="Date">Date (Newest First)</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col lg={3} xs={12} className={viewMode === 'details' ? 'd-lg-block d-none' : ''}>
                    <div className="list-group custom-list-group">
                        {filteredAndSortedItems.map((item, index) => (
                            <button
                                type="button"
                                className={`list-group-item list-group-item-action ${selectedItem && selectedItem.key === item.key ? 'selected' : ''}`}
                                key={index}
                                onClick={() => handleSelectItem(item)}
                            >
                                {item.key}
                            </button>
                        ))}
                    </div>
                </Col>
                <Col lg={9} xs={12}>
                    {selectedItem ? (
                        <div className="sticky-top">
                            <Button variant="secondary" className="mb-3 d-lg-none" onClick={handleBackToList}>
                                Back to List
                            </Button>
                            <ImageViewer url={selectedItem.url} />
                        </div>
                    ) : (
                        <div className="alert alert-info">Select an update to view details</div>
                    )}
                </Col>
            </Row>
        </Container>
    );
}

export default ListDetailDocuments;

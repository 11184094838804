// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add your custom styles for the dashboard */
.main-content-card {
    border-radius: 2.0rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.page-content-wrapper {
    background-color: #F4F8FC;
    min-height: 100vh; 
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/dashboard.css"],"names":[],"mappings":"AAAA,6CAA6C;AAC7C;IACI,qBAAqB;IACrB,mDAAmD;AACvD;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;AACrB","sourcesContent":["/* Add your custom styles for the dashboard */\n.main-content-card {\n    border-radius: 2.0rem;\n    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);\n}\n\n.page-content-wrapper {\n    background-color: #F4F8FC;\n    min-height: 100vh; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { formatCurrency } from '../../utils/formatCurrency';
import { formatDate } from '../../utils/formatDate';
import './grantRow.css';

const GrantRow = ({ grant }) => {
    return (
        <>
        <Row className='d-none d-md-flex grant-row'>
            <Col md={3}>
                {formatCurrency(grant.amount)}
            </Col>
            <Col md={7}>
                {grant.notes === "NaN" ? "No notes" : grant.notes}
            </Col>
            <Col md={2}>
                {formatDate(grant.date_approved)}
            </Col>
        </Row>

        <Row className='d-md-none grant-row'>
            <Col xs={4} className="grant-key">Amount</Col>
            <Col xs={8}>
                {formatCurrency(grant.amount)}
            </Col>
            <Col xs={4} className="grant-key">Date</Col>
            <Col xs={8}>
                {formatDate(grant.date_approved)}
            </Col>
            <Col xs={4} className="grant-key">Notes</Col>
            <Col xs={8}>
                {grant.notes === "NaN" ? "No notes" : grant.notes}
            </Col>
        </Row>
        </>
    );
}

export default GrantRow;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.grant-row {
    border-bottom: 1px solid #eee;
    padding: 10px 0;
    cursor: pointer;
    align-items: center;
    color: #292D32;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.grant-key {
    align-items: center;
    font-family: 'Poppins', sans-serif;
    color: #B5B7C0;
    font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/grantRow/grantRow.css"],"names":[],"mappings":";AACA;IACI,6BAA6B;IAC7B,eAAe;IACf,eAAe;IACf,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,kCAAkC;AACtC;;AAEA;IACI,mBAAmB;IACnB,kCAAkC;IAClC,cAAc;IACd,eAAe;AACnB","sourcesContent":["\n.grant-row {\n    border-bottom: 1px solid #eee;\n    padding: 10px 0;\n    cursor: pointer;\n    align-items: center;\n    color: #292D32;\n    font-size: 14px;\n    font-weight: 500;\n    font-family: 'Poppins', sans-serif;\n}\n\n.grant-key {\n    align-items: center;\n    font-family: 'Poppins', sans-serif;\n    color: #B5B7C0;\n    font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

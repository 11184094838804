import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './granteeSummary.css';
import { formatCurrency } from '../../utils/formatCurrency';

const GranteeSummary = ({ grantee }) => {
    if (!grantee.name) {
        return <div>Loading...</div>;
    }
    return (
        <>
        <Row>
            <Col className='header'>
                {grantee.name}
            </Col>
        </Row>
        <Row className='my-3'>
            <Col md={3} className="label">
                Description:
            </Col>
            <Col md={9} className='description'>
                {grantee.description}
            </Col>
        </Row>
        <Row className='my-3'>
            <Col xs={4} md={3} className="label">
                Total Giving:
            </Col>
            <Col xs={8} md={9} className='text-bold'>
                {formatCurrency(grantee.total_giving)}
            </Col>
            <Col xs={4} md={3} className="label">
                Website:
            </Col>
            <Col xs={8} md={9} className='description'>
                {grantee.web_address !== "NaN" ? <a href = {grantee.web_address} className='custom-link'>{grantee.web_address}</a> : <>None provided</>}
            </Col>
            <Col xs={4} md={3} className="label">
                Category:
            </Col>
            <Col xs={8} md={9}className='text-bold'>
                {grantee.category.name}
            </Col>
        </Row>
        <Row style={{ borderBottom: '1px solid #dee2e6', marginBottom: '15px' }}/>
        </>
    );
}

export default GranteeSummary;
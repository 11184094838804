// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styles for the link wrapping the row */
.grantee-row-link {
    text-decoration: none;
    color: inherit;
}

/* Styles for the row itself */
.grantee-row {
    border-bottom: 1px solid #eee;
    padding: 10px 0;
    cursor: pointer;
    align-items: center;
    color: #292D32;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/components/granteeRow/granteeRow.css"],"names":[],"mappings":"AAAA,yCAAyC;AACzC;IACI,qBAAqB;IACrB,cAAc;AAClB;;AAEA,8BAA8B;AAC9B;IACI,6BAA6B;IAC7B,eAAe;IACf,eAAe;IACf,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,kCAAkC;AACtC","sourcesContent":["/* Styles for the link wrapping the row */\n.grantee-row-link {\n    text-decoration: none;\n    color: inherit;\n}\n\n/* Styles for the row itself */\n.grantee-row {\n    border-bottom: 1px solid #eee;\n    padding: 10px 0;\n    cursor: pointer;\n    align-items: center;\n    color: #292D32;\n    font-size: 18px;\n    font-weight: 500;\n    font-family: 'Poppins', sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Nav } from "react-bootstrap";

export const LogoutButton = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <Nav className="logoutButton">
      <Nav.Link onClick={handleLogout}>Log Out</Nav.Link>
    </Nav>

  );
};

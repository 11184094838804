// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iframe-container {
    width: 100%;
    height: 80vh; /* Adjust based on preference */
    padding: 0;
    overflow: hidden; /* Ensures no scroll bars appear */
}

.iframe-container iframe {
    width: 100%;
    height: 100%;
    border: none; /* Optional: removes the border for a cleaner look */
}`, "",{"version":3,"sources":["webpack://./src/components/imageViewer/imageViewer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY,EAAE,+BAA+B;IAC7C,UAAU;IACV,gBAAgB,EAAE,kCAAkC;AACxD;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,YAAY,EAAE,oDAAoD;AACtE","sourcesContent":[".iframe-container {\n    width: 100%;\n    height: 80vh; /* Adjust based on preference */\n    padding: 0;\n    overflow: hidden; /* Ensures no scroll bars appear */\n}\n\n.iframe-container iframe {\n    width: 100%;\n    height: 100%;\n    border: none; /* Optional: removes the border for a cleaner look */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

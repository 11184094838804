import GrantRow from "../grantRow/grantRow";
import { Container, Row, Col } from "react-bootstrap";
import './grantList.css';

const GrantList = ({ grants }) => {
    return (
        <Container>
            <Row className="grant-list-header d-none d-md-flex">
                <Col md={3}>$ Amount</Col>
                <Col md={7}>Notes</Col>
                <Col md={2}>Date</Col>
            </Row>
            {(Array.isArray(grants) ? grants : []).map((grant) => (
                <GrantRow key={grant.id} grant={grant} />
            ))}
        </Container>
    );
}

export default GrantList;
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import SideBar from '../sidebar/sidebar';
import './dashboard.css';

const Dashboard = ({ children }) => { // Using destructuring to get children directly
    return (
        <Container fluid className="p-0">
            <Row>
                <SideBar />
                <Col xs={12} xl={{span: 10, offset: 2}} className='page-content-wrapper'>
                    <Card className="main-content-card m-1 m-sm-2 m-md-3 m-lg-5">
                        <Card.Body>
                            {children}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )   
}

export default Dashboard;

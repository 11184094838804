import axios from 'axios';


const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Separate PATCH API function
const ApiPatch = async (endpoint, data, token) => {
    const url = `${BASE_URL}/${endpoint}`;
    try {
        await axios.patch(url, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return true;
    } catch (error) {
        console.error("API request failed:", error);
        return false;
    }
};

export default ApiPatch;
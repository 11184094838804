
import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";


const ProposalsPage = () => {
    return (
        <Container>
          <Row>
            <Col>
              <h1>Proposals</h1>
              {/* Add your code here */}
                <p>Coming soon</p>
            </Col>
          </Row>
        </Container>
      );
    };

export default ProposalsPage;
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './granteeRow.css';

const GranteeRow = ({ grantee }) => {
    return (
        <Link to={`/grantees/${grantee.id}`} className='grantee-row-link'>
            <Row className='grantee-row'>
                <Col xs={7}>
                    {grantee.name}
                </Col>
                <Col xs={5}>
                    {grantee.category}
                </Col>
            </Row>
        </Link>
    );
};

export default GranteeRow;
